import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, map } from 'rxjs/operators';
import { CLP_ACTION, LOGIN_ACTION } from '@seco/login';
import { AlertService } from '../services/alert/alert.service';
import { AlertModel, AlertType, Flow } from '../model/alert.model';
import { Store } from '@ngrx/store';
import { CoreState } from './core.reducer';
import { updateTitle, updateFormAlert } from './core.actions';
import { TitleInformation, TitleState } from '../model/title-information';

@Injectable()
export class CoreEffect {
  constructor(
    private readonly actions$: Actions,
    private readonly alertService: AlertService,
    private readonly store: Store<CoreState>
  ) {}

  /**
   * Listen OTP action from @seco/login, get payload and display warning message
   */
  otpViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.OTP_NEEDED),
        tap((data: any) => {
          const alert: AlertModel = {
            type: AlertType.WARNING,
            state: Flow.OTP,
            data: data.payload
          };
          this.alertService.addAlert(alert);
        })
      ),
    { dispatch: false }
  );

  otpJumpLoginViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.JUMP_LOGIN_OTP_NEEDED),
        tap((data: any) => {
          const alert: AlertModel = {
            type: AlertType.WARNING,
            state: Flow.OTP,
            data: data.payload
          };
          this.alertService.addAlert(alert);
        })
      ),
    { dispatch: false }
  );

  ottJumpLoginViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.JUMP_LOGIN_OTT_NEEDED),
        tap((data: any) => {
          const alert: AlertModel = {
            type: AlertType.WARNING,
            state: Flow.OTT,
            data: data.payload
          };
          this.alertService.addAlert(alert);
        })
      ),
    { dispatch: false }
  );

  clpApiErrorListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CLP_ACTION.ERROR_RECEIVED),
        tap((data: any) => {
          const message = data.payload[0]?.error?.message;
          if (message && message.toUpperCase().includes('CLP')) {
            const alert: AlertModel = {
              type: AlertType.ERROR,
              data: message
            };
            this.alertService.addAlert(alert);
          }
        })
      ),
    { dispatch: false }
  );

  forgetPwdViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.INIT_FORGET_PWD_FLOW),
        tap((data: any) => {
          this.updateTitle({
            title: $localize`:@@newlogin.resetPwd.title:Reset your password`,
            subtitle: data.subtitle,
            titleState: TitleState.forgetPwd
          });
        })
      ),
    { dispatch: false }
  );

  jumpLoginViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.INIT_JUMP_LOGIN_FLOW),
        tap((data: any) => {
          this.updateTitle({
            title: $localize`:@@newlogin.jumpLogin.title:Log in`,
            subtitle: data.payload,
            titleState: TitleState.jumpLogin
          });
        })
      ),
    { dispatch: false }
  );

  validatePwdViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.INIT_VALIDATE_PWD_FLOW),
        tap((data: any) => {
          this.updateTitle({
            title: $localize`:@@newlogin.login.confirm:Confirm new password`,
            subtitle: data.payload,
            titleState: TitleState.validatePwd
          });
        })
      ),
    { dispatch: false }
  );

  validatePwdResetViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.VALIDATE_PWD_END),
        tap(() => {
          const alert: AlertModel = {
            type: AlertType.SUCCESS,
            message: $localize`:@@newlogin.login.validatePwd.success:Your password was reset successfully`,
            state: Flow.MESSAGE
          };
          this.updateTitle({
            title: $localize`:@@newlogin.login.signIn:Sign in`,
            subtitle: '',
            titleState: TitleState.login
          });
          this.alertService.addAlert(alert);
        })
      ),
    { dispatch: false }
  );

  expiredPwdViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.EXPIRED_PASSWORD),
        tap((data: any) => {
          this.updateTitle({
            title: $localize`:@@newlogin.login.expiredPwd:Expired password`,
            subtitle: data.payload,
            titleState: TitleState.expiredPwd
          });
        })
      ),
    { dispatch: false }
  );

  ddnaViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.DDNA_NEEDED),
        tap(() => {
          this.updateTitle({
            title: $localize`:@@newlogin.login.signIn:Sign in`,
            subtitle: $localize`:@@newlogin.ddna.subtitle:Register a Digital DNA (DDNA)`,
            titleState: TitleState.ddna
          });
        })
      ),
    { dispatch: false }
  );

  backToLoginViewListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGIN_ACTION.CHANGE_ACCOUNT),
        tap(() => {
          this.updateTitle({
            title: $localize`:@@newlogin.login.signIn:Sign in`,
            subtitle: '',
            titleState: TitleState.login
          });
        })
      ),
    { dispatch: false }
  );

  formErrorListener$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOGIN_ACTION.FORM_ERROR),
      map((data: any) => updateFormAlert(data.payload))
    )
  );

  private updateTitle(titleInformation: TitleInformation) {
    this.store.dispatch(updateTitle(titleInformation));
  }
}
